import * as QueryString from "query-string-new";

import {
    BusinessRulesListResponse,
    Client,
    ColumnType,
    DMNObject,
    DropdownWithHeadersOption,
    Rule,
    RuleObject,
    RuleType,
    SaveData,
    Status,
    TriggerData,
    TriggerResponseData
} from "../types";
import {DropdownOption} from "@medispend/common/src/types";
import {capitalize} from "@medispend/common/src/utils/string/capitalize";
import {INIT_COMMENTS} from "../Dashboard/constants";
import {getDateFromTimestamp} from "@medispend/common/src/utils/date";
import {DROPDOWN_DATE_FORMAT} from "@medispend/common/src/constants";
import {initThen, initWhen} from "../Dashboard/BusinessRule/BusinessRuleEdit/BusinessRuleEditorTable/constants";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";
import {MultiSelectOption} from "@medispend/common/src/components/MSSelect/BaseSelect/types";
import {BRListFilters, RequestBodyFilters} from "../common/types";
import * as _ from "lodash";


export const getBusinessRulesListData = (data: BusinessRulesListResponse): Rule[] => {
    const businessRulesData = data?._embedded?.rules;
    return businessRulesData ? businessRulesData.map((rule) => ({
        ...rule,
        clientName: rule.client.displayName,
        process: rule.process.name,
        application: rule.application.name,
        ruleType: capitalize(rule.ruleType),
        status: capitalize(rule.status)
    })
    ) : [];
};

export const getTriggersDataContent = (data: TriggerResponseData):DropdownOption[] => {
    return data?._embedded?.contexts?.map((trigger) => ({uiLabel: trigger.name, value: trigger.id, name: trigger.name})) || [];
};

export const getTriggerInputContext = (data: TriggerData): DropdownWithHeadersOption[] => {
    const triggersArray = data?.inputObjects.map(element => element?.sections?.map((element) => {
        const inputOptions = element.inputs.map((option) => ({...option, isLast: true, type: ColumnType.INPUT,
            uiLabel: option.description}));
        return {name: element.name, options: inputOptions};}));
    const planeTriggerArray = triggersArray.flat();
    return planeTriggerArray;
};

export const getTriggerInputsWithoutHeaders = (data: DropdownWithHeadersOption[], rules: Rule[]): MultiSelectOption[] => {
    return data?.reduce((prevValue: any, currentValue) => {
        const newOptions = currentValue.options?.filter((opt: any) => !rules.some(rule => rule.name === opt.name));
        return [...(prevValue || []), ...newOptions.map(opt => ({...opt, value: opt.id, uiLabel: opt.name}))];}, []);
};

export const getTriggerActionsContext = (data: TriggerData):DropdownWithHeadersOption[] => {
    const actionData = data?.actions || [];
    return actionData.map((action) =>
        ({
            name: action.actionType, options: action?.data ? action.data.map((data: any, index: number) =>
                ({
                    type: index > 0 ? ColumnType.PROPERTY : ColumnType.OUTPUT,
                    name: `${action.actionType}.${data.keyName}`,
                    dataType: data.keyDataType,
                    id: action.id
                })) : []
        }));
};

export const getQueryString = (requestBody: RequestBodyFilters<BRListFilters> ): string => {
    const {filters, searchQuery} = requestBody;
    const search = searchQuery ? `searchByName=${searchQuery}` : "";

    Object.keys(filters).forEach((key: keyof BRListFilters) => {
        if (!filters[key]) delete filters[key];
    });
    const filterQueryString = QueryString.stringify(filters, {arrayFormat: "comma"});
    const formatSearch = filterQueryString ? `&${search}` : search;
    return `${filterQueryString}${formatSearch}`;
};

export const setRulesFromDmn = (dmnObject: DMNObject): Rule[][] => {
    return dmnObject?.dtRules?.map((rule) => {
        const {inputs, outputs, comment} = rule;
        const filteredInputs = dmnObject?.additionalInputIds?.length ? dmnObject?.inputs?.filter(input => !dmnObject?.additionalInputIds.find(addInput => input.id === addInput )) : dmnObject?.inputs;
        const newInputs = filteredInputs.map((input, index) => ({...input, value: inputs[index], type: ColumnType.INPUT}));
        const outputGroups = _.groupBy(dmnObject.outputs, (output) => {
            const [, group] = output.name.split(".");
            return group;
        });
        const newOutputs = dmnObject?.outputs?.map((output, index) => {
            const [, group] = output?.name?.split(".");
            const outputIndexInGroup = outputGroups[group].findIndex(out => out.name === output.name);
            const outputType = outputIndexInGroup === 0 ? ColumnType.OUTPUT : ColumnType.PROPERTY;
            return {
                ...output,
                value: outputs[index],
                type: outputType
            };});
        return [...(newInputs?.length ? newInputs : [initWhen]), ...(newOutputs.length ? newOutputs : [initThen]), {...INIT_COMMENTS, value: comment}];
    });
};

export const setRuleHeaders = (dmnObject: DMNObject): Rule[] => {
    const filteredInputs = dmnObject?.additionalInputIds?.length ? dmnObject?.inputs?.filter(input => !dmnObject?.additionalInputIds.find(addInput => input.id === addInput )) : dmnObject?.inputs;

    const newInputs = filteredInputs?.map((input, index, array) => ({...input, type: ColumnType.INPUT, isFirst: !index, isLast: index === array.length - 1}));
    const outputGroups = _.groupBy(dmnObject.outputs, (output) => {
        const [, group] = output.name.split(".");
        return group;
    });
    const newOutputs = dmnObject?.outputs?.map((output, index, array) => {
        const [outputName, group] = output?.name?.split(".");
        const outputIndexInGroup = outputGroups[group].findIndex(out => out.name === output.name);
        const outputType = outputIndexInGroup === 0 ? ColumnType.OUTPUT : ColumnType.PROPERTY;
        const actionIndex = dmnObject?.actions?.findIndex(element => element.actionType === outputName);
        const length = (outputType === ColumnType.OUTPUT && !output?.length && actionIndex !== -1) ? dmnObject?.actions[actionIndex].data.length : output?.length;
        const actionId = dmnObject?.actions?.find(action => action.actionType === outputName);
        return {...output, type: outputType, isFirst: !index, isLast: index === array.length - 1, length, id: actionId.id};
    });
    return [...(newInputs?.length ? newInputs : [initWhen]), ...(newOutputs?.length ? newOutputs : [initThen]), INIT_COMMENTS];
};

export const setRulesForSave = (formData: SaveData, createData: any, clientName: string, uuid: string): RuleObject => {
    const {ruleName: name = "", ruleDescription: description = "", ruleType = "", application: applicationId,
        process: processId = "", status, effectiveDate, expiryDate, version, minorVersion
    } = createData;
    const {trigger: contextId, policy: hitPolicy, ruleRows, rules, additionalInputs} = formData;
    const {newInputs, newOutputs, newValues, actionIds} = prepareRuleForSave(ruleRows, rules);
    const outputs = newOutputs.map(output => ({dataType: output.dataType, name: output.name}));
    return {
        rule: {
            name,
            ruleType,
            ...(uuid && {uuid}),
            applicationId,
            processId,
            contextId,
            client: (isMedispendSubDom && ruleType === RuleType.STANDARD) ? "medispend" : clientName,
            status,
            description,
            ...(effectiveDate && {effectiveDate: getDateFromTimestamp(effectiveDate, DROPDOWN_DATE_FORMAT)}),
            ...(expiryDate && {expiryDate: getDateFromTimestamp(expiryDate, DROPDOWN_DATE_FORMAT)}),
            version: version ? version : 1,
            minorVersion: minorVersion ? minorVersion : 0
        },
        dmnObject: {
            hitPolicy,
            dtRules: newValues,
            inputs: [...newInputs, ...additionalInputs],
            outputs,
            actionIds: actionIds,
            additionalInputIds: additionalInputs?.map(input => input.value) || []
        }
    };
};

export const ruleHeadersValid = (ruleHeaders: Rule[]): boolean => !ruleHeaders.find(header => header.isPlaceholder);

export const compareRuleType = (rule: Rule, columnType: ColumnType): boolean => {
    const {type, isPlaceholder} = rule;
    switch (columnType) {
        case ColumnType.INPUT: {
            return !isPlaceholder && type === ColumnType.INPUT;
        }
        case ColumnType.OUTPUT: {
            return !isPlaceholder && (type === ColumnType.OUTPUT || type === ColumnType.PROPERTY);
        }
        default: {
            return type === ColumnType.COMMENTS;
        }
    }
};

export const prepareRuleForSave = (ruleRows: Rule[][], ruleHeaders: Rule[]) => {
    const newInputs = ruleHeaders.filter(header => compareRuleType(header, ColumnType.INPUT))
        .map(header => ({id: header.id, description: header.description, dataType: header.dataType, name: header.name}));
    const filteredOutputs = ruleHeaders.filter(header => compareRuleType(header, ColumnType.OUTPUT));
    const newOutputs = filteredOutputs.map(header => ({dataType: header.dataType, name: header.name, length: header?.length}));
    const actionIds = ruleHeaders.filter(header => header.type === ColumnType.OUTPUT && !header.isPlaceholder).map(output => output.id || "");
    const newValues = ruleRows.map(row => {
        const inputs = row.filter(rule => compareRuleType(rule, ColumnType.INPUT)).map(input => input.value || "");
        const outputs = row.filter(rule => compareRuleType(rule, ColumnType.OUTPUT)).map(output => output.value || "");
        const comment = row.filter(rule => compareRuleType(rule, ColumnType.COMMENTS)).map(output => output.value)[0];
        return {inputs, outputs, comment};
    });
    const newOutputsValues = ruleRows.map(row => row.filter(rule => compareRuleType(rule, ColumnType.OUTPUT)))
        .map(outputRow => outputRow.map(output => output.value));
    return {newInputs, newOutputs, newValues, newOutputsValues, actionIds};
};

export const inputExistCheck = (inputs: DropdownWithHeadersOption[], name: string): boolean => {
    if (!inputs.length) return true;
    const names = inputs.reduce((previousValue, currentValue) => [...previousValue, ...currentValue.options], []);
    return names.some(option => name === option.name);
};

export const outputExistCheck = (outputs: DropdownWithHeadersOption[], name: string): boolean => {
    if (!outputs.length) return true;
    return outputs.some((option) => option.name === name.split(".")[0]);
};

export const isDisabled = (isCopy: boolean, isEdit: boolean): boolean => {
    return isCopy && isMedispendSubDom ? false : !(isMedispendSubDom && isEdit);
};

export const isClientDropdownDisabled = (client: string, status: Status, isCopy: boolean): boolean => {
    // View/edit Custom Rule: should be able to change only in the DRAFT status, because of saving to the Client's DB
    const viewEditRestriction = !isCopy && !_.isEmpty(client) && status !== Status.DRAFT;
    // Copy to the Custom Rule from Standard Rule: should be able during copying process, but not for Client's subdomains
    const copyRuleRestriction = isCopy && !isMedispendSubDom;
    return viewEditRestriction || copyRuleRestriction;
};

export const removeMedispendClient = (clients: Array<Client>) : Array<Client> => {
    return clients.filter((client: Client) => {
        return client.name !== "medispend";
    });
};

export function adjustMutableRulesGroup(colAction: "add" | "delete", indexCol: number, rules: Rule[]): void {
    for (let i = indexCol + 1; i < rules.length; i++) {
        const rule = rules[i];


        if (!rule || ![ColumnType.OUTPUT, ColumnType.PROPERTY].includes(rule.type)) continue;

        const [name, group, action] = rule.name.split(".");
        rule.name = `${name}.${Number(group) + 1}.${action}`;
        const newGroup = colAction === "add" ? Number(group) + 1 : Number(group) - 1;
        rule.name = `${name}.${newGroup}.${action}`;
    }
}