import React, {FunctionComponent, useState, useEffect} from "react";
import classNames from "classnames";

import {ButtonSizes, ButtonColors, ButtonTypes} from "../MSButton/types";
import {SelectOption} from "../MSSelect/BaseSelect/types";
import {BaseSelectWithSearch} from "../MSDropdown/BaseSelectWithSearch";
import {FilterValue} from "../../types";
import {MSLabel} from "../MSLabel";
import {FieldHandleAction, FormFieldHint, Mode} from "../../formBuilder/types";

import "./index.scss";

export interface MSSelectProps {
    options?: SelectOption[];
    title?: string;
    placeholder?: string;
    size?: keyof typeof ButtonSizes;
    buttonType?: keyof typeof ButtonTypes;
    variant?: (typeof ButtonColors)[keyof typeof ButtonColors];
    activeVariant?: (typeof ButtonColors)[keyof typeof ButtonColors] | null;
    value?: string | number;
    handleChange?: (
        optionData: FilterValue,
        name: string,
        isHandleAction: boolean,
        handleAction: FieldHandleAction
    ) => void;
    isHandleAction?: boolean;
    handleAction?: FieldHandleAction;
    fieldLabel: string;
    isRequired: boolean;
    error: string;
    name?: string;
    placeVariant?: "form" | "grid";
    mode?: Mode;
    shouldShowClear?: boolean;
    isPencilDisplayed?: boolean;
    isDisabled?: boolean;
    showTypeToSearch?: boolean;
    hint?: FormFieldHint;
}

export const MSFormSelect: FunctionComponent<MSSelectProps> = (props) => {
    const {
        options = [],
        placeholder = "Please select",
        buttonType = ButtonTypes.default,
        placeVariant = "form",
        shouldShowClear = false,
        error,
        isRequired,
        fieldLabel,
        value = null,
        handleChange,
        name,
        isHandleAction,
        handleAction,
        variant,
        size = ButtonSizes.md,
        mode,
        isPencilDisplayed,
        isDisabled,
        showTypeToSearch = false,
        hint
    } = props;

    const [selectedOption, setSelectedOption] = useState([]);

    const handleClearOptions = () => {
        setSelectedOption(null);
        handleChange && handleChange(null, name, isHandleAction, handleAction);
    };

    useEffect(() => {
        const foundOption = options?.find((o) => o?.value === value);
        setSelectedOption(foundOption ? [foundOption] : []);
    }, [value, options]);

    const handleClick = (optionData: SelectOption) => {
        setSelectedOption(optionData ? [optionData] : []);
        handleChange && handleChange(optionData.value, name, isHandleAction, handleAction);
    };

    const selectContent = (
        <>
            {fieldLabel && (
                <MSLabel
                    isRequired={isRequired}
                    fieldLabel={fieldLabel}
                    isPencilDisplayed={isPencilDisplayed}
                    error={error}
                />
            )}
            <div className={classNames("ms-form-select", {"has-error": error?.length})}>
                {mode === "READ" ? (
                    <div className="value">{selectedOption[0]?.uiLabel || "-"}</div>
                ) : (
                    <BaseSelectWithSearch
                        disabled={isDisabled}
                        selectedOptions={selectedOption}
                        activeVariant={null}
                        options={options}
                        dropdownTitle={(selectedOption && selectedOption[0]?.uiLabel) || placeholder}
                        buttonType={buttonType}
                        size={size}
                        variant={variant || ButtonColors.grey100}
                        onClick={handleClick}
                        shouldShowClear={shouldShowClear}
                        handleClearOptions={handleClearOptions}
                        showTypeToSearch={showTypeToSearch}
                        error={error}
                    />
                )}
                {!!hint && <div className={classNames("field-hint", `field-hint--${hint.type}`)}>{hint.text}</div>}
                {!!error?.length && <div className="error-message">{error}</div>}
            </div>
        </>
    );

    return (
        <>
            {placeVariant === "form" && <div className="ms-form-group">{selectContent}</div>}
            {placeVariant === "grid" && <>{selectContent}</>}
        </>
    );
};
