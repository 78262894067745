import React, {FC, MouseEvent, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";

import {ColumnType, Condition, Direction, DropdownWithHeadersOption, Rule} from "../../../../types";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {useOnClickOutside} from "@medispend/common/src/components/hooks/clickOutside";
import {inputExistCheck, outputExistCheck} from "../../../../helpers/businessRules";

import "./scss/_EditorHeaderTableCell.scss";
import {useGlobalModalContext} from "@medispend/common/src/components/MSGlobalModal";
import {MODAL_TYPES} from "@medispend/common/src/components/MSGlobalModal/constants";


export interface EditorHeaderTableCellProps {
    onAdd: (option?: DropdownWithHeadersOption | Rule) => void;
    dropdownInputOptions: DropdownWithHeadersOption[];
    dropdownOutputOptions: DropdownWithHeadersOption[];
    onChange: (name?: DropdownWithHeadersOption | Rule, length?: number, type?: string) => void;
    column: Rule;
    checkTrigger: () => void;
    disabled: boolean;
    isSaveTriggered: boolean;
    onRulesSet: (rules: Rule[]) => void;
    status: string;
    onSwap: (index: number, direction: Direction) => void;
    index: number,
    isNextInput: boolean
}

type EventType = MouseEvent<Element, globalThis.MouseEvent>

export const EditorHeaderTableCell: FC<EditorHeaderTableCellProps> = (props: EditorHeaderTableCellProps) => {
    const {
        onAdd = () => {/**/
        },
        onChange = () => {/**/
        },
        dropdownInputOptions = [],
        dropdownOutputOptions = [],
        column,
        checkTrigger = () => {/**/},
        disabled, isSaveTriggered, onSwap, index, isNextInput
    } = props;
    const {
        name = "",
        dataType = "",
        isLast = false,
        isPlaceholder = false,
        type = "",
        length = 1
    } = column;
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAddDropdown, setShowAddDropdown] = useState(false);
    const [showOutputDropdown, setShowOutputDropdown] = useState(false);
    const [showAddOutputDropdown, setShowAddOutputDropdown] = useState(false);
    const [error, setError] = useState(false);
    const dropdownRef: React.RefObject<HTMLDivElement> = useRef();
    const addDropdownRef: React.RefObject<HTMLDivElement> = useRef();
    const inputDropdownRef: React.RefObject<HTMLDivElement> = useRef();
    const inputAddDropdownRef: React.RefObject<HTMLDivElement> = useRef();
    useOnClickOutside(dropdownRef, () => setShowDropdown(false));
    useOnClickOutside(addDropdownRef, () => setShowAddDropdown(false));
    useOnClickOutside(inputDropdownRef, () => setShowOutputDropdown(false));
    useOnClickOutside(inputAddDropdownRef, () => setShowAddOutputDropdown(false));
    const {showModal} = useGlobalModalContext();

    const addButton = () => {
        if (disabled) return;
        const onClick = (event: EventType) => {
            event.stopPropagation();
            if (type === ColumnType.INPUT) {
                setShowAddDropdown(!showAddDropdown);
            } else {
                setShowAddOutputDropdown(!showAddOutputDropdown);
            }
        };
        return (
            <div className="circle-add-button add-button" onClick={(event) => onClick(event)}>
                <MSIcon icon="fas fa-plus-circle" />
            </div>
        );
    };

    const onColumnDelete = () => {
        showModal(MODAL_TYPES.CONFIRM_MODAL, {
            title: "Are you sure you want to remove this column?",
            onConfirm: () => onChange(null, length, type)
        });
    };

    useEffect(() => {
        setError(!validInputCheck());
    }, [dropdownInputOptions, dropdownOutputOptions, isSaveTriggered, column]);

    const optionsMenu = () => {
        if (disabled) return;
        return (
            <Dropdown className="ellipsis-dropdown" drop="down">
                <Dropdown.Toggle><div className="row-options">
                    <MSIcon icon="fas fa-ellipsis-v" />
                </div></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onColumnDelete()}>Delete</Dropdown.Item>
                    {column.type === ColumnType.INPUT && <>
                        <Dropdown.Item onClick={() => onSwap(index, Direction.LEFT)} disabled={index === 0}>Move Left</Dropdown.Item>
                        <Dropdown.Item onClick={() => onSwap(index, Direction.RIGHT)} disabled={!isNextInput}>Move Right</Dropdown.Item>
                    </>}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const renderDropdown = () => {
        if (disabled) return;
        return (<><Dropdown.Menu show={showDropdown} className="dropdown-input-custom" ref={dropdownRef}>
            {dropdownInputOptions.map((dropdownOpt, index) => {
                return (<React.Fragment key={`${index}-first-dropdown-container`}>
                    <Dropdown.Header key={`${index}-dropdown-input-header`}>{dropdownOpt.name}</Dropdown.Header>
                    {dropdownOpt.options.map((opt, i) => {
                        return (<Dropdown.Item key={`${i}-dropdown-input`} value={opt.value} onClick={() => {
                            onChange(opt, length, type);
                            setShowDropdown(false);
                        }}>{opt.name}</Dropdown.Item>);
                    })}
                </React.Fragment>);
            })}
        </Dropdown.Menu>
        <Dropdown.Menu show={showAddDropdown} className="dropdown-add-input-custom" ref={addDropdownRef}>
            {dropdownInputOptions.map((dropdownOpt, index) => {
                return (<React.Fragment key={`${index}-second-dropdown-container`}>
                    <Dropdown.Header key={`${index}-dropdown-add-input-header`}>{dropdownOpt.name}</Dropdown.Header>
                    {dropdownOpt.options.map((opt, i) => {
                        return (<Dropdown.Item key={`${i}-dropdown-add-input`} value={opt.value} onClick={() => {
                            onAdd(opt);
                            setShowAddDropdown(false);
                        }}>{opt.name}</Dropdown.Item>);
                    })}
                </React.Fragment>);
            })}
        </Dropdown.Menu>
        <Dropdown.Menu show={showOutputDropdown} className="dropdown-add-input-custom" ref={inputDropdownRef}>
            {dropdownOutputOptions.map((dropdownOpt, i) => {
                return (<React.Fragment key={`${i}-third-dropdown-container`}>
                    <Dropdown.Item key={`${i}-dropdown-output`} onClick={() => {
                        onChange(dropdownOpt, length, type);
                        setShowOutputDropdown(false);
                    }}>{dropdownOpt.name}</Dropdown.Item>
                </React.Fragment>);
            })}
        </Dropdown.Menu>
        <Dropdown.Menu show={showAddOutputDropdown} className="dropdown-add-input-custom"
            ref={inputAddDropdownRef}>
            {dropdownOutputOptions.map((dropdownOpt, i) => {
                return (<React.Fragment key={`${i}-fourth-dropdown-container`}>
                    <Dropdown.Item key={`${i}-dropdown-add-output`} onClick={() => {
                        onAdd(dropdownOpt);
                        setShowAddOutputDropdown(false);
                    }}>{dropdownOpt.name}</Dropdown.Item>
                </React.Fragment>);
            })}
        </Dropdown.Menu>
        </>
        );
    };

    const setCondition = (type: string) => {
        switch (type) {
            case ColumnType.INPUT:
                return column?.isFirst ? Condition.WHEN : Condition.AND;
            case ColumnType.OUTPUT:
                return column?.isFirst ? Condition.THEN : Condition.AND;
            case ColumnType.PROPERTY:
                return Condition.AND;
            default:
                return "";
        }
    };

    const onCellClick = (type: string) => {
        checkTrigger();
        switch (type) {
            case ColumnType.INPUT: {
                if (dropdownInputOptions.length > 0) {
                    setShowDropdown(!showDropdown);
                }
                break;
            }
            case ColumnType.OUTPUT: {
                if (dropdownOutputOptions.length > 0) {
                    setShowOutputDropdown(!showOutputDropdown);
                }
                break;
            }
            default:
                break;
        }
    };

    const validInputCheck = () => {
        if (isPlaceholder && isSaveTriggered ){
            return false;
        }
        else {
            if (type === ColumnType.INPUT && !isPlaceholder) {
                return inputExistCheck(dropdownInputOptions, column.name);
            }
            else if ((type === ColumnType.OUTPUT || type === ColumnType.PROPERTY) && !isPlaceholder){
                return outputExistCheck(dropdownOutputOptions, column.name);
            }
        }
        return true;
    };

    return (
        <th className={classNames("cell", name === "Comments" ? "full-width" : "", error ? "invalid-field" : "")}>
            <div className="dropdown-wrapper">
                {renderDropdown()}
            </div>
            <div className="cell-container">
                {(type === "Output" || type === "Input") && !isPlaceholder && optionsMenu()}
                {!isPlaceholder && <div className="cell-condition">{setCondition(type)}</div>}
                <div className={classNames("cell-name", (type === ColumnType.OUTPUT || type === ColumnType.INPUT) && !isPlaceholder ? "highlight" : "")} onClick={() => {
                    onCellClick(type);
                }}>{name}</div>
                {!isPlaceholder && <div className="cell-type">{dataType}</div>}
                {((type === ColumnType.PROPERTY || type === ColumnType.OUTPUT) && isLast || (type === ColumnType.INPUT)) && !isPlaceholder && addButton()}
            </div>
        </th>
    );
};
