import React, {FC, useEffect, useState} from "react";
import * as _ from "lodash";

import {MSFormSelect} from "@medispend/common/src/components/MSFormSelect";
import {ButtonSizes} from "@medispend/common/src/components/MSButton/types";
import {EditorHeaderTableCell} from "./EditorHeaderTableCell";
import {EditorTableRow} from "./EditorTableRow";
import {initThen, initWhen, POLICY} from "./constants";
import {ColumnType, Direction, DropdownWithHeadersOption, ErrorPosition, Position, Rule} from "../../../../types";
import {MSIcon} from "@medispend/common/src/components/MSIcon";
import {adjustMutableRulesGroup, compareRuleType, inputExistCheck, isDisabled, outputExistCheck} from "../../../../helpers/businessRules";
import {MSLoader} from "@medispend/common/src/components/MSLoader";
import {ERROR_INPUT_UNAVAILABLE} from "../constants";

import "./scss/style.scss";
import {isMedispendSubDom} from "@medispend/admin-common/src/env";

/* 
 this has to be added, since we are not able to upgrade our TS yet.
 https://github.com/microsoft/TypeScript/issues/48829 
*/
declare global {
    interface Array<T> {
        findLastIndex(
            predicate: (value: T, index: number, obj: T[]) => unknown,
            thisArg?: any
        ): number
    }
}

export interface BusinessRuleEditorTableProps {
    inputs: DropdownWithHeadersOption[],
    outputs: DropdownWithHeadersOption[],
    checkTrigger: () => void;
    onRulesSet: (rules: Rule[]) => void;
    rules: Rule[];
    hitPolicy: string;
    onHitPolicyChange: (policy: string) => void;
    ruleRows: Rule[][],
    onRuleRowsChange: (rules: Rule[][]) => void;
    isEdit: boolean;
    isCopy: boolean;
    loading: boolean;
    isSaveTriggered: boolean;
    topError: string[],
    botError: string[],
    setErrorMessage: (position: ErrorPosition, error: string) => void;
    removeErrorMessage: (position: ErrorPosition, error: string) => void;
    clearError: (position: ErrorPosition) => void;
    status: string;
}

export const BusinessRuleEditorTable: FC<BusinessRuleEditorTableProps> = ({inputs = [],
    outputs = [],
    checkTrigger,
    rules, onRulesSet = () => {/**/}, hitPolicy, onHitPolicyChange,
    ruleRows, onRuleRowsChange, isEdit = true, isCopy, loading,
    isSaveTriggered, setErrorMessage, removeErrorMessage, status}) => {
    useEffect(() => {
        setInitRules(rules);
    }, [rules]);
    const [initRules, setInitRules] = useState(rules);
    const [buffer, setBuffer] = useState(null);

    useEffect(() => {
        if (inputs.length || outputs.length) {
            const filteredInputs = initRules.filter(rule => compareRuleType(rule, ColumnType.INPUT));
            const filteredOutputs = initRules.filter(rule => compareRuleType(rule, ColumnType.OUTPUT));
            const inputsErrors = filteredInputs.filter(input => !inputExistCheck(inputs, input.name));
            const outputsErrors = filteredOutputs.filter(output => !outputExistCheck(outputs, output.name));
            if (inputsErrors.length || outputsErrors.length) {
                setErrorMessage(Position.TOP, ERROR_INPUT_UNAVAILABLE);
            } else {
                removeErrorMessage(Position.TOP, ERROR_INPUT_UNAVAILABLE);
            }
        }
    }, [outputs, inputs, initRules]);

    const addCol = (type: ColumnType, indexCol: number, option: DropdownWithHeadersOption) => {
        const cloneRules = _.cloneDeep(initRules);
        const cloneRuleRows = _.cloneDeep(ruleRows);
        if ([ColumnType.OUTPUT, ColumnType.PROPERTY].includes(type)) {
            adjustMutableRulesGroup("add", indexCol, cloneRules);
        }
        const lastUpdatedOutput = cloneRules.findLastIndex((rule, index) => rule.type === ColumnType.OUTPUT && index <= indexCol);
        const [, group] = cloneRules[lastUpdatedOutput]?.name.split(".") || [];
        const colToAdd = type === ColumnType.INPUT ? [{
            ...option
        }] : option.options.map((option, index, inputArray): Rule => {
            const splitName = option.name.split(".");
            return {
                ...option,
                name: `${splitName[0]}.${Number(group) + 1}.${splitName[1]}`,
                isLast: index === inputArray.length - 1,
                length: inputArray.length
            };
        });
        cloneRules.splice(indexCol + 1, 0, ...colToAdd);
        const newRows = cloneRuleRows.map(row => {
            const cloneRow = _.cloneDeep(row);
            cloneRow.splice(indexCol + 1, 0, ..._.cloneDeep(colToAdd));
            return cloneRow;
        });
        onRulesSet(cloneRules);
        onRuleRowsChange(newRows);
    };

    const onAddRow = () => {
        const cloneRuleRows = _.cloneDeep(ruleRows);
        const cloneRules = _.cloneDeep(initRules);
        cloneRuleRows.push(cloneRules);
        onRuleRowsChange(cloneRuleRows);
    };

    const onRemoveRow = (index: number) => {
        const cloneRuleRows = _.cloneDeep(ruleRows);
        cloneRuleRows.splice(index, 1);
        onRuleRowsChange(cloneRuleRows);
    };

    const getChangedInputRules = (option: DropdownWithHeadersOption, index: number) => {
        const cloneRules = _.cloneDeep(initRules);
        const cloneRuleRows = _.cloneDeep(ruleRows);
        if (option) {
            cloneRules[index] = _.cloneDeep(option);
            cloneRuleRows.forEach(row => row[index] = {..._.cloneDeep(option), value: ""});
        }
        else {
            const count = cloneRules.filter(option => option.type === ColumnType.INPUT).length;
            if (count > 1) {
                cloneRules.splice(index, 1);
                cloneRuleRows.forEach(row => row.splice(index, 1));
            }
            else {
                cloneRules[index] = _.cloneDeep(initWhen);
                cloneRuleRows.forEach(row => row[index] = _.cloneDeep(initWhen));
            }
        }
        return {newRules: cloneRules, newRows: cloneRuleRows};
    };

    const getChangedOutputRules = (option: DropdownWithHeadersOption, indexCol: number, length: number) => {
        const cloneRules = _.cloneDeep(initRules);
        const cloneRuleRows = _.cloneDeep(ruleRows);
        if (option) {
            const newOptions = _.cloneDeep(option).options.map((opt, index, inputArray): Rule => {
                const splitName = opt.name.split(".");
                return {
                    ...opt,
                    name: `${splitName[0]}.${indexCol}.${splitName[1]}`,
                    isLast: index === inputArray.length - 1,
                    length: inputArray.length,
                    value: ""
                };
            });
            cloneRules.splice(indexCol, length, ..._.cloneDeep(newOptions));
            cloneRuleRows.forEach(row => row.splice(indexCol, length, ..._.cloneDeep(newOptions)));
        } else {
            const outputs = cloneRules.filter(option => option.type === ColumnType.OUTPUT);
            const count = outputs.length;
            if (count > 1) {
                adjustMutableRulesGroup("delete", indexCol, cloneRules);
                cloneRules.splice(indexCol, length);
                cloneRuleRows.forEach(row => row.splice(indexCol, length));
            }
            else {
                cloneRules.splice(indexCol, length, _.cloneDeep(initThen));
                cloneRuleRows.forEach(row => row.splice(indexCol, length, _.cloneDeep(initThen)));
            }
        }
        return {newRules: cloneRules, newRows: cloneRuleRows};
    };

    const onColumnChange = (option: DropdownWithHeadersOption, index: number, length: number, type: string) => {
        const {newRules, newRows} = type === ColumnType.INPUT ? getChangedInputRules(option, index) : getChangedOutputRules(option, index, length);
        const columnInput = newRules.findIndex(rule => rule.type === ColumnType.INPUT);
        if (columnInput >= 0) {
            newRules[columnInput].isFirst = true;
        }
        const columnOutput = newRules.findIndex(rule => rule.type === ColumnType.OUTPUT);
        if (columnOutput >= 0) {
            newRules[columnOutput].isFirst = true;
        }
        const columnProperty = newRules.findLastIndex(rule => rule.type === ColumnType.PROPERTY);
        if (columnProperty >= 0) {
            newRules[columnProperty].isLast = true;
        }
        onRulesSet(newRules);
        onRuleRowsChange(newRows);
    };

    const onChangeRow = (rowIndex: number, cellIndex: number, newValue: string | number) => {
        const cloneRuleRows = _.cloneDeep(ruleRows);
        cloneRuleRows[rowIndex][cellIndex].value = newValue;
        onRuleRowsChange(cloneRuleRows);
    };

    const onCopyRow = (row: Rule[]) => {
        const cloneRow = _.cloneDeep(row);
        setBuffer(cloneRow);
    };

    const onPasteRow = (index: number) => {
        const cloneRuleRows = _.cloneDeep(ruleRows);
        index === ruleRows.length - 1 ? cloneRuleRows.push(buffer) : cloneRuleRows.splice(index + 1, 0, buffer);
        onRuleRowsChange(cloneRuleRows);
        setBuffer(null);
    };

    const onMoveRow = (index: number, direction: Direction = Direction.UP) => {
        const cloneRuleRows = _.cloneDeep(ruleRows);
        const swapIndex = direction === Direction.UP ? index - 1 : index + 1;
        const tmp = cloneRuleRows[index];
        cloneRuleRows[index] = cloneRuleRows[swapIndex];
        cloneRuleRows[swapIndex] = tmp;
        onRuleRowsChange(cloneRuleRows);
    };

    const onSwapColumns = (index: number, direction: Direction = Direction.LEFT) => {
        const cloneRules = _.cloneDeep(initRules);
        const cloneRuleRows = _.cloneDeep(ruleRows);
        const swapIndex = direction === Direction.LEFT ? index - 1 : index + 1;
        const tempRuleHeader = {...cloneRules[index], isFirst: cloneRules[swapIndex]?.isFirst};
        cloneRules[index] = {...cloneRules[swapIndex], isFirst: cloneRules[index]?.isFirst};
        cloneRules[swapIndex] = tempRuleHeader;
        cloneRuleRows.forEach((row) => {
            const tempRowData = row[index];
            row[index] = row[swapIndex];
            row[swapIndex] = tempRowData;
        });
        onRulesSet(cloneRules);
        onRuleRowsChange(cloneRuleRows);
    };

    return (<div className="table-container">
        {loading && <MSLoader />}
        <div className="table-body-container">
            <table className="table-body">
                <thead>
                    <tr><th colSpan={10000}>
                        <div className="table-header">
                            <h3>Hit policy:</h3>
                            <div className="policy-select">
                                <MSFormSelect fieldLabel=""
                                    size={ButtonSizes.lg}
                                    isRequired={false}
                                    isDisabled={isDisabled(isCopy, isEdit)}
                                    error=""
                                    value={hitPolicy}
                                    options={POLICY}
                                    placeholder="Unique"
                                    handleChange={(value: string) => onHitPolicyChange(value)}
                                />
                            </div>
                        </div>
                    </th>
                    </tr>
                    <tr>
                        <th>
                            <div className="blank-col"></div>
                        </th>
                        {initRules.map((col, i, array) => {
                            const isNextInput = i + 1 < array.length ? array[i + 1].type === ColumnType.INPUT : false;
                            return (<EditorHeaderTableCell
                                isSaveTriggered={isSaveTriggered}
                                disabled={isCopy && isMedispendSubDom ? false : isMedispendSubDom && !isEdit}
                                checkTrigger={checkTrigger}
                                dropdownInputOptions={inputs}
                                dropdownOutputOptions={outputs}
                                column={col}
                                onRulesSet={onRulesSet}
                                key={`${i}-rule-headers`}
                                onAdd={(option: DropdownWithHeadersOption) => {
                                    addCol(col?.type, i, option);
                                }}
                                onChange={(name: DropdownWithHeadersOption, length: number, type: string) => {onColumnChange(name, i, length, type);}}
                                status={status}
                                onSwap={onSwapColumns}
                                index={i}
                                isNextInput={isNextInput}
                            />);}
                        )}
                    </tr>
                </thead>
                <tbody>
                    {ruleRows.map((row, rowIndex, array) => {
                        return (<EditorTableRow ruleData={row}
                            index={rowIndex}
                            count={array.length}
                            key={`${rowIndex}-rule-rows`}
                            onRemoveRow={(index: number) => onRemoveRow(index)}
                            onChangeRow={(cellIndex: number, newValue) => onChangeRow(rowIndex, cellIndex, newValue)}
                            disabled={isDisabled(isCopy, isEdit)}
                            onCopyRow={onCopyRow}
                            onPasteRow={onPasteRow}
                            onMoveRow={onMoveRow}
                            buffer={buffer}
                        />);
                    })}
                </tbody>
                {!isDisabled(isCopy, isEdit) && <tfoot>
                    <tr>
                        <td colSpan={1}>
                            <div className="add-row-button" onClick={() => onAddRow()}>
                                <MSIcon icon="fas fa-plus-circle" />
                            </div>
                        </td>
                    </tr>
                </tfoot>}
            </table>
        </div>
    </div>);
};
