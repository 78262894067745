import {ClientProperty} from "../types";
import {canBeTreatedAsBoolean, getStringAsBoolean} from "./string";

export function clientHasAllProperties(clientProperties: ClientProperty[], requiredProperties: ClientProperty[]): boolean {
    return !requiredProperties || requiredProperties.every((required) => clientHasAnyProperty(clientProperties, required));
}

export function clientHasAnyProperty(clientProperties: ClientProperty[], required: ClientProperty): boolean {
    const isPropertyBoolean = canBeTreatedAsBoolean(required.propertyValue);
    if (isPropertyBoolean) {
        return clientProperties.some(prop => prop.propertyKey === required.propertyKey
            && getStringAsBoolean(prop.propertyValue) === getStringAsBoolean(required.propertyValue));
    } else {
        return clientProperties.some(prop => prop.propertyKey === required.propertyKey && prop.propertyValue === required.propertyValue);
    }
}

export function getClientPropertyValue(clientProperties: ClientProperty[], propertyKey: string): string {
    return clientProperties.find(property => property.propertyKey === propertyKey)?.propertyValue;
}

export function getClientPropertyListValue(clientProperties: ClientProperty[], propertyKey: string): string[] {
    const value = getClientPropertyValue(clientProperties, propertyKey);
    return value ? value.split(",").map(val => val.trim()) : [];
}
