import React, {useState, useEffect} from "react";
import {BehaviorSubject} from "rxjs";
import {MSButton} from "../MSButton";
import {MSModal} from "../MSModal";

const promptBody = "Are you sure you want to continue?";

export enum PromptType {
    REGULAR = "REGULAR",
    HARD_STOP = "HARD_STOP"
}
export interface PromptSubject {
    show?: boolean;
    message?: string;
    type?: PromptType
    acceptLabel?: string;
    rejectLabel?: string;
    onReject?: () => void;
    onAccept?: () => void;
}

const promptSubject = new BehaviorSubject<PromptSubject>({
    show: false
});

export const MSPrompt: React.FC = () => {
    const [promptState, setPromptState] = useState<PromptSubject>({
        show: false
    });

    useEffect(() => {
        promptSubject.subscribe((subject) => {
            setPromptState((prev) => ({...prev, ...subject}));
        });
    }, []);

    const handleClose = () => {
        if (promptState.onReject) {
            promptState.onReject();
        }
        hidePrompt();
    };

    const handleApprove = () => {
        if (promptState.onAccept) {
            promptState.onAccept();
        }
        hidePrompt();
    };

    if (!promptState.show) return null;

    return (
        <MSModal
            show={promptState.show}
            onClose={handleClose}
            body={
                <>
                    <h4>{promptState.message}</h4>
                    {promptState.type !== PromptType.HARD_STOP && <div>{promptBody}</div>}
                </>
            }
            backdropClassName="modal-backdrop back-drop-fixed"
            footer={
                <>
                    {promptState.rejectLabel && (
                        <MSButton key="cancel" onClick={handleClose} variant="grey-100" size="lg">
                            {promptState.rejectLabel}
                        </MSButton>
                    )}
                    {promptState.acceptLabel && (
                        <MSButton key="approve" onClick={handleApprove} variant="green" size="lg">
                            {promptState.acceptLabel}
                        </MSButton>
                    )}
                </>
            }
        />
    );
};

export function showPrompt(options: PromptSubject): void {
    promptSubject.next({...options, show: true});
}

export function hidePrompt(): void {
    promptSubject.next({show: false});
}
